<template>
  <vc-date-picker
    v-model="dateModel"
    :mode="withTime ? 'dateTime' : 'date'"
    :locale="lang"
    is24hr
    :masks="masks"
    :modelConfig="modelConfig"
    :minute-increment="minutesIncrement"
    update-on-input
    :input-debounce="500"
  >
    <template v-slot="{ inputValue, inputEvents }">
      <input
        :placeholder="placeholder !== '' ? placeholder : examplePlaceholder"
        class="form-control"
        :value="inputValue"
        v-on="inputEvents"
      />
    </template>
  </vc-date-picker>
</template>

<script>
// https://vcalendar.io/

import i18nService from "@/core/services/i18n.service.js";

export default {
  name: "SingleDatePicker",
  props: {
    withTime: {
      type: Boolean,
      default: false,
    },
    minutesIncrement: {
      type: Number,
      default: 5,
    },
    placeholder: {
      type: String,
      default: "",
    },
    // использовать :date.sync
    date: {
      default: null,
    },
  },
  data() {
    return {
      masks: {
        inputDateTime24hr: "DD.MM.YYYY HH:mm",
      },
      modelConfig: {
        type: "string",
        mask: "iso",
        timeAdjust: "00:00:00",
      },
    };
  },
  computed: {
    dateModel: {
      get() {
        return this.date ? new Date(this.date) : null;
      },
      set(value) {
        this.$emit("update:date", value);
      },
    },
    lang() {
      return i18nService.getActiveLanguage();
    },

    examplePlaceholder() {
      const options = {};
      options.day = "2-digit";
      options.month = "2-digit";
      options.year = "numeric";

      if (this.withTime) {
        options.hour = "2-digit";
        options.minute = "2-digit";
      }

      return (
        this.$t("COMMON.EXAMPLE") +
        ": " +
        new Intl.DateTimeFormat(this.lang, options)
          .format(new Date())
          .split(",")
          .join("")
      ); // костыль для удаления запятой
    },
  },
};
</script>

<style scoped>
input {
}
</style>
