<template>
  <div>
    <b-overlay :show="showOverlay" rounded="sm">
      <b-card style="max-width: 100%; width: 100%" class="mb-2">
        <b-card-body>
          <b-form-group
            id="field-name"
            label-cols-lg="2"
            :label="$t('COMMON.NAME')"
            label-for="field-name"
          >
            <b-form-input
              id="field-name-input"
              v-model="dto.name"
            ></b-form-input>
          </b-form-group>

          <b-form-group
            id="field-externalId"
            label-cols-lg="2"
            :label="$t('ACCOUNTS.EXTERNAL_ID')"
            label-for="field-externalId-input"
          >
            <b-form-input
              id="field-externalId-input"
              v-model="dto.externalId"
            ></b-form-input>
          </b-form-group>

          <b-form-group
            id="field-active"
            label-cols-lg="2"
            :label="$t('COMMON.ACTIVE')"
            label-for="field-active-input"
          >
            <b-form-checkbox
              id="checkbox-active-input"
              v-model="dto.active"
              name="checkbox-active"
              class="pt-2"
              switch
              size="lg"
            >
            </b-form-checkbox>
          </b-form-group>

          <b-form-group
            id="field-blockAfterDt"
            label-cols-lg="2"
            :label="$t('NEWS.DATE')"
            label-for="field-patronymic-blockAfterDt"
          >
            <single-date-picker :date.sync="dto.elementDt">
            </single-date-picker>
          </b-form-group>

          <b-form-group
            id="field-startDt"
            label-cols-lg="2"
            :label="$t('NEWS.ACTIVE_FROM')"
            label-for="field-patronymic-startDt"
          >
            <single-date-picker :date.sync="dto.startDt"> </single-date-picker>
          </b-form-group>

          <b-form-group
            id="field-endDt"
            label-cols-lg="2"
            :label="$t('NEWS.ACTIVE_TO')"
            label-for="field-patronymic-endDt"
          >
            <single-date-picker :date.sync="dto.endDt"> </single-date-picker>
          </b-form-group>

          <b-form-group
            id="field-sortOrder"
            label-cols-lg="2"
            :label="$t('NEWS.SORT_ORDER')"
            label-for="field-externalId-input"
          >
            <b-form-input
              id="field-sortOrder-input"
              v-model="dto.sortOrder"
            ></b-form-input>
          </b-form-group>

          <div v-for="(e, index) in dto.sectionPattern.fields" :key="index">
            <b-form-group
              v-if="dto.sectionId != null"
              id="field-nameLocalized"
              label-cols-lg="2"
              :label="$t('BLOG.' + e.name)"
              :label-for="'field-' + index + '-input'"
            >
              <div
                v-if="
                  e.elementFieldTypeId ===
                  constants.ELEMENT_FIELD_TYPES.SINGLE_LINE_TEXT
                "
              >
                <b-form-input
                  :id="'field-' + index + '-input'"
                  v-model="dto.fields[e.code]"
                ></b-form-input>
              </div>
              <div
                v-if="
                  e.elementFieldTypeId ===
                  constants.ELEMENT_FIELD_TYPES.MULTI_LINE_TEXT
                "
              >
                <el-tiptap
                  id="field-text-editor"
                  v-model="dto.fields[e.code]"
                  :extensions="extensions"
                  output="html"
                  height="600px"
                  :lang="getLang()"
                  :spellcheck="true"
                />
              </div>
              <div
                v-if="
                  e.elementFieldTypeId === constants.ELEMENT_FIELD_TYPES.NUMBER
                "
              >
                <b-form-input
                  type="number"
                  :id="'field-' + index + '-input'"
                  v-model.number="dto.fields[e.code]"
                ></b-form-input>
              </div>
            </b-form-group>
          </div>
        </b-card-body>
        <div style="float: right">
          <b-button
            variant="success"
            v-bind:disabled="saving"
            v-on:click="onSaveClicked"
            >{{ $t("COMMON.SAVE") }}</b-button
          >
        </div>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import { API_REQUEST } from "@/core/services/store/api.module";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Application from "../../application";
import {
  ElementTiptap,
  Doc,
  Text,
  Paragraph,
  Heading,
  Bold,
  Italic,
  Strike,
  Underline,
  Link,
  Image,
  Table,
  TableHeader,
  TableRow,
  TableCell,
  Blockquote,
  ListItem,
  BulletList, // use with ListItem
  OrderedList, // use with ListItem
  TextAlign,
  Indent,
  HorizontalRule,
  TextColor,
  TextHighlight,
  LineHeight,
  // CodeBlock,
  // TrailingNode,
  // FormatClear,
  History,
  Preview,
  // Print,
  Fullscreen,
} from "element-tiptap";
import "element-ui/lib/theme-chalk/index.css";
import "element-tiptap/lib/index.css";
import cyrillicToTranslit from "cyrillic-to-translit-js";
import SingleDatePicker from "../component/SingleDatePicker";

export default {
  name: "property",
  components: {
    SingleDatePicker,
    "el-tiptap": ElementTiptap,
  },
  data() {
    return {
      save: {
        resource: "/platform/api/content/element/save",
        requestType: "POST",
        requestParams: {},
      },

      dto: {
        id: null,
        name: null,
        active: false,
        elementDt: null,
        endDt: null,
        externalId: null,
        startDt: null,
        sortOrder: null,
        sectionId: null,
        sectionPattern: {
          fields: [{ elementFieldTypeId: null }],
        },
        nameLocalized: null,
        fields: {},
      },

      saving: false,

      constants: Application.constants,
      storedFiles: {},

      showOverlay: true,

      extensions: [
        new Doc(),
        new Text(),
        new Paragraph(),
        new Heading({ level: 5 }),
        new Bold({ bubble: true }),
        new Underline({ bubble: true }),
        new Italic({ bubble: true }),
        new Strike({ bubble: true }),
        new HorizontalRule(),
        new Link(),
        new Image({}),
        new Table({
          resizable: true,
        }),
        new TableHeader(),
        new TableRow(),
        new TableCell(),
        new Blockquote({ bubble: true }),
        new TextAlign(),
        new ListItem(),
        new BulletList({ bubble: true }),
        new OrderedList({ bubble: true }),
        new Indent(),
        new LineHeight(),
        new TextColor({
          bubble: true,
          colors: [
            "#f44336",
            "#e91e63",
            "#9c27b0",
            "#673ab7",
            "#3f51b5",
            "#2196f3",
            "#03a9f4",
            "#00bcd4",
            "#009688",
            "#4caf50",
            "#8bc34a",
            "#cddc39",
            "#ffeb3b",
            "#ffc107",
            "#ff9800",
            "#ff5722",
            "#000000",
          ],
        }),
        new TextHighlight({
          bubble: true,
          colors: [
            "#f44336",
            "#e91e63",
            "#9c27b0",
            "#673ab7",
            "#3f51b5",
            "#2196f3",
            "#03a9f4",
            "#00bcd4",
            "#009688",
            "#4caf50",
            "#8bc34a",
            "#cddc39",
            "#ffeb3b",
            "#ffc107",
            "#ff9800",
            "#ff5722",
            "#000000",
          ],
        }),
        new History(),
        new Preview(),
        new Fullscreen(),
      ],
    };
  },

  watch: {
    "dto.name": function (val) {
      if (this.dto.id != null) {
        return;
      }

      let tr = cyrillicToTranslit().transform(this.dto.name, "_");
      if (tr.length > 254) {
        tr = tr.substr(0, 254);
      }
      this.dto.externalId = tr.toLowerCase();
    },
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("NEWS.BLOG"), route: "/news" },
      { title: this.$t("NEWS.CREATE_NEW_BLOG") },
    ]);
  },

  async created() {
    if (this.$route.params.id != null) {
      await this.loadNews();
    } else {
      await this.loadPattern();
    }
    this.showOverlay = false;
  },

  methods: {
    async loadNews() {
      await this.$store
        .dispatch(API_REQUEST, {
          resource: "/platform/api/content/element/" + this.$route.params.id,
          requestType: "GET",
          requestParams: { includeSectionPattern: true },
        })
        .then((response) => (this.dto = response))
        .catch(this.onError);
    },
    async loadPattern() {
      await this.$store
        .dispatch(API_REQUEST, {
          resource:
            "/platform/api/content/section/" +
            this.$route.params.sectionId +
            "/pattern",
          requestType: "GET",
        })
        .then((response) => {
          this.dto.sectionId = this.$route.params.sectionId;
          this.dto.sectionPattern = response;
        })
        .catch(this.onError);
    },
    selectFileClick: function (code) {},
    getLang() {
      return localStorage.getItem("language") || "en";
    },

    async onSaveClicked() {
      this.saving = true;
      const request = this.save;
      request.dto = this.dto;
      await this.$store
        .dispatch(API_REQUEST, request)
        .then((response) => {
          this.dto.id = response.id;

          this.$bvToast.toast(this.$t("COMMON.SAVED_SUCCESSFULLY"), {
            title: this.$t("COMMON.SUCCESS"),
            variant: "success",
            autoHideDelay: 5000,
          });
        })
        .catch(this.onError);
      this.saving = false;
    },

    onError: function (response) {
      if (response && response.config) response = response.data;

      this.$bvToast.toast(
        response && response.message
          ? response.message
          : this.$t("COMMON.UNKNOWN_ERROR"),
        {
          title: this.$t("COMMON.ERROR"),
          variant: "danger",
          autoHideDelay: 5000,
        }
      );
    },
  },
};
</script>
<style>
.hidden {
  display: none;
}
.form-group {
  margin-bottom: 1rem;
}
</style>
